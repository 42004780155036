import {START_LOADING, END_LOADING, FETCH_ITEMS, FETCH_ITEMS_USER, ADD_NEW_ITEM, UPDATE_ITEM, DELETE_ITEM } from '../actions/constants';

const items = (state = { isLoading: true, items: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_ITEMS:
      return {
        ...state,
        items: action.payload,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
      case FETCH_ITEMS_USER:
        return { ...state, items: action.payload };
     
    case ADD_NEW_ITEM:
      return { ...state, items: [...state.items, action.payload] };
    case UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_ITEM:
      return { ...state, items: state.items.filter((item) => item._id !== action.payload) };
    default:
      return state;
  }
};

export default items;
