import React, { useState } from 'react';
import { TableRow,TableCell, Checkbox } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateUser } from '../actions/auth';

const UserRow = ({ user }) => {
   
  const [isActive, setIsActive] = useState(user.isActive);
  const form = { isActive: !isActive };
    const dispatch = useDispatch();
    
  const handleCheckboxChange = () => {
    if(isActive) {
    const confirmUpdate = window.confirm('Are you sure you want to inactive this Sales-Person?');
    if(confirmUpdate){
      setIsActive(!isActive);

      // Update the user's isActive status in the database
      // using your preferred method (e.g., API call, Redux action)
  
      // Example using Redux:
       dispatch(updateUser(user._id, form));
    }
   
  }else{
    const confirmUpdate = window.confirm('Are you sure you want to active this Sales-Person?');
    if(confirmUpdate){
      setIsActive(!isActive);

      // Update the user's isActive status in the database
      // using your preferred method (e.g., API call, Redux action)
  
      // Example using Redux:
       dispatch(updateUser(user._id, form));
    }
  };
  }
  return (
    <TableRow>
    
      <TableCell>
        <Checkbox
          checked={isActive}
          onChange={handleCheckboxChange}
          style={{color: "#ef7f1a"}}
          edge="start"
        />
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
