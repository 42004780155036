import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { deleteProfile, getProfiles } from "../actions/profile";
import { getUsers, deleteUser } from "../actions/auth";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableContainer from "@material-ui/core/TableContainer";
import { useMediaQuery } from "@material-ui/core";
import UserRow from "./userRow";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Tab,
  Tabs,
  Checkbox,
  TextField,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import Spinner from "../components/Spinner/Spinner";
import AdminLogin from "./adminLogin/adminLogin";
import AdminProfiles from "./adminProfiles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(10),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1)
      // top: theme.spacing(8),
      // right: theme.spacing(2),
      // left: theme.spacing(2),
    },

  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  addButton: {
    // display: 'flex',
    // alignItems: 'center',
    // position: 'absolute',
    // top: theme.spacing(5),
    // right: theme.spacing(15),

    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      // top: theme.spacing(8),
      // right: theme.spacing(2),
      // left: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "Top",
      position: "absolute",
      top: theme.spacing(6.5),
      right: theme.spacing(15),
      bottom: theme.spacing(2),
      
    // Add the bottom margin here
    },
  },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "150vh",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  contentContainer: {
    width: "100%",
    marginRight: theme.spacing(2),
  },
  responsiveContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  tableContainer: {
    overflowX: "auto",
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    backgroundColor: "#1976D2", // Replace 'primary.main' with your desired blue color
    color: "#fff", // White text color
  },
  addUser: {
    height : "55px",
    marginTop: "16px",
    marginLeft: "16px",
  },
}));

const AdminDashboard = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { profiles } = useSelector((state) => state.profiles);
  const { users } = useSelector((state) => state.auth);
  console.log("users: ", users);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  useEffect(() => {
    dispatch(getProfiles());
  }, [location]);

  useEffect(() => {
    dispatch(getUsers());
  }, [location]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleUsernameClick = (id) => {
    history.push(`/userStats/${id}`);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteUser = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Sales-Person?');
    if(confirmDelete){
    dispatch(deleteUser(id, openSnackbar));
    const toBeDeleteProfile = profiles.find(
      (profile) => profile.userId[0] === id
    );
    //  console.log("tobeDeletedProfile",toBeDeleteProfile._id);
    dispatch(deleteProfile(toBeDeleteProfile._id, openSnackbar));
    }

  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }
  const filteredRows = users.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <Container
        style={{
          width: isMobile ? "100%":"90%",
          paddingTop: "2px",
          paddingBottom: "2px",
          border: "none",
          minHeight:isMobile ?740: "510px",
        }}
      >
        <div className={classes.addButton}>
          <TextField
            id="search-bar"
            label="Search"
            variant="outlined"
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            variant="outlined"
            style={{color: "#ef7f1a",borderColor:"#ef7f1a" }}
            startIcon={<AddIcon />}
            onClick={handleOpenDialog}
            className={classes.addUser}
          >
            Add SalesPerson
          </Button>
        </div>
        <div className={classes.root}>
          <div className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  {/* <TableCell>Password</TableCell> */}
                  <TableCell>Active</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredRows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredRows
                ).map((user, index) => 
                 

                  <TableRow key={user._id}>
                   
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      onClick={() => {
                        handleUsernameClick(user._id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {user.name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    {/* <TableCell>********</TableCell> */}
                    <TableCell>
                      {user?.email === "admin@admin.com" ? null : (
                        <UserRow user={user} />
                      )}
                    </TableCell>
                    <TableCell>
                      {user?.userType === "admin" ? "Admin" : "Salesperson"}
                    </TableCell>
                    <TableCell>
                      {user?.email === "admin@admin.com" ? null : (
                        <div className={classes.buttonGroup}>
                          <Button
                            variant="outlined"
                            style={{color: "#ef7f1a",borderColor: "#ef7f1a"}}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              handleDeleteUser(user._id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      )}
                    </TableCell>
                   
                  </TableRow>
                          
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={6}
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
          
          >
            <DialogTitle
              className={classes.dialogTitle}
              id="customized-dialog-title"
              style={{backgroundColor: "#ef7f1a"}}
            >
              Add Salesperson
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <AdminLogin />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" style={{marginRight:"auto"}}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </div>
  );
};

export default AdminDashboard;
