
import { FETCH_ALL_MEETINGS, ADD_NEW_MEETING, UPDATE_MEETING, DELETE_MEETING, GET_MEETING, START_LOADING, END_LOADING, FETCH_MEETING_BY_USER } from '../actions/constants'

const meetings = (state = { isLoading: true, meetings: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALL_MEETINGS:
        return {
          ...state,
          meetings: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_MEETING_BY_USER:
        return { ...state, meetings: action.payload };

      case GET_MEETING:
        return { ...state, meeting: action.payload };
      case ADD_NEW_MEETING:
        return { ...state, meetings: [...state.meetings, action.payload] };
      case UPDATE_MEETING:
        return { ...state, meetings: state.meetings.map((meeting) => (meeting._id === action.payload._id ? action.payload : meeting)) };
      case DELETE_MEETING:
        return { ...state, meetings: state.meetings.filter((meeting) => meeting._id !== action.payload) };
      default:
        return state;
    }
  };

  export default meetings



//   const meetings =( state = { meetings: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, meetings: action.payload }

//         case GET_MEETING:
//             return { ...state, meeting: action.payload }

//         case ADD_NEW:
//             return { ...state, meetings: [...state.meetings, action.payload] }

//         case UPDATE:
//             return { ...state, meetings: state.meetings.map((meeting) => meeting._id === action.payload ? action.payload : meeting) }
        
//         case DELETE: 
//         return {...state, meetings: state.meetings.filter((meeting) => meeting._id !== action.payload)}
        
//         default:
//             return state;
//     }
// }

// export default meetings
