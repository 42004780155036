import React, { useEffect, useState } from "react";
import { toCommas } from "../../utils/utils";
import styles from "./TodayBookings.module.css";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesByUser, getInvoices } from "../../actions/invoiceActions";
import { getUsers } from "../../actions/auth";
import { getMeetings } from "../../actions/meetingActions";
import { getAllClients } from "../../actions/clientActions";
import { Order } from "../Dashboard/Icons";
import NoData from "../svgIcons/NoData";
// import Donut from './Donut'
import moment from "moment";

import Spinner from "../Spinner/Spinner";

const TodayBookings = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { users } = useSelector((state) => state.auth);
  //(users);
  const [reports, setReports] = useState([]);
  const [rows, setRows] = useState([]);
  const { invoices, isLoading } = useSelector((state) => state?.invoices);
  const { meetings } = useSelector((state) => state?.meetings);
  //(invoices);
  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    if (user?.result?.userType === "user") {
      const userRows = invoices.filter(
        (inv) => inv.creator[0] === user?.result?._id
      );
      const userRowsToday = userRows.filter((inv) =>
        moment(inv.date).isSame(today, "day")
      );
      setRows(userRowsToday);
    } else {
      const rows = invoices.filter((inv) =>
        moment(inv.date).isSame(today, "day")
      );
      setRows(rows);
    }

    // eslint-disable-next-line
  }, [invoices]);

  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);
  // const unpaid = invoices?.filter((invoice) => (invoice.status === 'Unpaid') || (invoice.status === 'Partial'))

  //sort payment history by date

  let totalPaid = 0;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].totalAmountReceived !== undefined) {
      totalPaid += rows[i].totalAmountReceived;
    }
  }

  let totalAmount = 0;
  //const currentDate = new Date(); // Get the current date

  for (let i = 0; i < rows.length; i++) {
    {
      totalAmount += rows[i].total;
    }
  }

  useEffect(() => {
    dispatch(getInvoices());
  }, [location, dispatch]);

  if (!user) {
    history.push("/login");
  }
  const openInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (rows.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <NoData />
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No Bookings for Today.
        </p>
      </div>
    );
  }
  return (
    <div className={styles.pageContainer}>
      <div className={styles.dateContainer}>
        <h1 className={styles.date}> Today's Booking</h1>
        <h2 className={styles.date}>{moment().format("Do MMM YYYY")}</h2>
      </div>
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          {rows.map((invoice, index) => (
            <li key={index} className={styles.listItem}   onClick={() => openInvoice(invoice._id)}>
              <div>
                <p>{invoice.client.name}</p>
                <p>{invoice.pax}</p>
                {/* <h2>{moment(invoice.bookingDate).format("Do MMM YYYY")}</h2> */}
                <h2>{invoice.client.agentCode}</h2>
              </div>
              <div>
                <Order />
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default TodayBookings;
