import {
  AUTH,
  LOGOUT,
  UPDATE_USER,
  FETCH_USERS,
  START_LOADING,
  END_LOADING,
  DELETE_USER
} from "../actions/constants";

const authReducer = (
  state = { authData: null, isLoading: true, users: [] },
  action
) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      // console.log(action?.data)
      return { ...state, authData: action?.data };
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("profile");
      return { ...state, authData: null };

    case UPDATE_USER:
      // return {...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user))}
      // localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      //  console.log(action?.data)
      return { users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user))};
      case DELETE_USER:
        return { ...state, users: state.users.filter((user) => user._id !== action.payload) };
    default:
      return state;
  }
};

export default authReducer;
