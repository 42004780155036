import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    marginLeft: "10rem",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  };

  const headingStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "15px",
  };

  const listItemStyle = {
    marginBottom: "8px",
  };

  return (
    <div style={containerStyle}>
      <p>
        This privacy policy sets out how Hangout uses and protects any information that you give us when you use this website.
        Hangout is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website/application, then you can be assured that it will only be used in accordance with this privacy statement.
        Hangout may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from August 01, 2020.
      </p>
      {/* Add the rest of the content here */}
      <p>We may collect the following information:</p>
      <ul>
        <li>name</li>
        <li>email address</li>
        <li>address for food delivery</li>
      </ul>
      {/* Add the rest of the content here */}
      <p>
        <strong>What do we do with the information we gather?</strong>
        We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
      </p>
      <p>
        <strong>Delivery of Food</strong>
        We use the Information to deliver Food at your Home/ office.
      </p>
      {/* Add the rest of the content here */}
      <p>
        <strong>Security</strong>
        We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
      </p>
      {/* Add the rest of the content here */}
      <p>
        <strong>Links to other websites</strong>
        Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
      </p>
      {/* Add the rest of the content here */}
      <p>
        <strong>Controlling your personal information</strong>
        You may choose to restrict the collection or use of your personal information in the following ways:
      </p>
      <ul>
        <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</li>
        <li>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@hangoutfoods.com</li>
        <li>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</li>
        <li>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to - info@hangoutfoods.com .</li>
        <li>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
