import * as api from '../api/index';
import { START_LOADING, END_LOADING, FETCH_ITEMS, FETCH_ITEMS_USER, ADD_NEW_ITEM, UPDATE_ITEM, DELETE_ITEM } from './constants';

export const getAllItems = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchItems();
   // console.log(data)
    dispatch({ type: FETCH_ITEMS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};
export const getItemsByUser =(searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
  const  { data:{data} }= await api.fetchItemsByUser(searchQuery)
    dispatch({ type: FETCH_ITEMS_USER, payload: data });
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log("getItemsByuser in itemActions error")
    console.log(error.response)
    
  }
}
export const createItem = (item, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.addItem(item);
    dispatch({ type: ADD_NEW_ITEM, payload: data });
    openSnackbar("Item added successfully");
  } catch (error) {
    console.log(error);
  }
};

export const updateItem = (id, item, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.updateItem(id, item);
    dispatch({ type: UPDATE_ITEM, payload: data });
    openSnackbar("Item updated successfully");
  } catch (error) {
    console.log(error);
  }
};

export const deleteItem = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteItem(id);
    dispatch({ type: DELETE_ITEM, payload: id });
    openSnackbar("Item deleted successfully");
  } catch (error) {
    console.log(error);
  }
};
