import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ItemContext } from "../../Context/ItemContext";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createItem, updateItem } from "../../actions/itemActions";
import { useSnackbar } from "react-simple-snackbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ButtonGroup,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  inputStyle: {
    width: "100%",
    fontFamily: "kruti-101",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = (props) => {
  const classes = useStyles();
  return <MuiDialogContent {...props} />;
};

const DialogActions = (props) => {
  const classes = useStyles();
  return <MuiDialogActions {...props} />;
};

const AddItem = ({ setOpen, open }) => {
  const classes = useStyles();
  const location = useLocation();
  const [itemData, setItemData] = useState({
    itemName: "",
    unitPrice: "",
    itemType: "",
    commission: {
      commissionType: "0",
      commissionValue: "",
    },
    itemId: [],
  });
  const { itemId, setItemId } = useContext(ItemContext);
 
  const dispatch = useDispatch();
  const item = useSelector((state) =>
    itemId ? state.items.items.find((i) => i._id === itemId) : null
  );
  const [isEditMode, setIsEditMode] = useState(false); // New state variable
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState({});

  const handleSubmitItem = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (itemId) {
        dispatch(updateItem(itemId, itemData, openSnackbar));
      } else {
        dispatch(createItem(itemData, openSnackbar));
      }
      clear();
      handleClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "commission") {
      setItemData((prevData) => ({
        ...prevData,
        commission: {
          ...prevData.commission,
          commissionValue: value,
        },
      }));
    } else {
      setItemData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
     
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the specific field
    }));
  };
  const handleCommissionTypeChange = (type) => {
    // Optionally, you can reset the commission value when changing the commission type
    setItemData((prevData) => ({
      ...prevData,
      commission: {
        ...prevData.commission,
        commissionType: type,
        commissionValue: "", // Reset commission value when changing type
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      commission: "", // Clear the error for the specific field
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
// console.log(itemData.commission.commissionType)
// console.log(itemData.commission.commissionValue)
// console.log(itemData.unitPrice)
    if (itemData.itemName.trim() === "") {
      newErrors.itemName = "Item name is required";
      isValid = false;
    }
    if (itemData.unitPrice.trim() === "") {
      newErrors.unitPrice = "Unit price is required";
      isValid = false;
    }
    // if (itemData.barcode.trim() === "") {
    //   newErrors.barcode = "barcode is required";
    //   isValid = false;
    // }
    if (itemData.itemType.trim() === "") {
      newErrors.itemType = "type is required";
      isValid = false;
    }
    if (itemData.commission.commissionType.trim() === "") {
      newErrors.commission = "Commission Type is required";
      isValid = false;
    }
    if (itemData.itemType.trim() === "") {
      newErrors.itemType = "type is required";
      isValid = false;
    }
    if (
      itemData.commission.commissionType === "0" &&
      itemData.commission.commissionValue > parseInt(itemData.unitPrice) 

    ){
      newErrors.commission = "Invalid value for Flat Type";
      isValid = false;
    }
   if( itemData.commission.commissionType === "1" &&
    itemData.commission.commissionValue > 100){
      newErrors.commission = "Invalid value for Percentage Type";
      isValid = false;
    }
      setErrors(newErrors);
    return isValid;
  };

  const clear = () => {
    setItemData({
      itemName: "",
      unitPrice: "",
      itemType: "",
      commission: {
        commissionType: "0",
        commissionValue: "",
      },
      itemId: [],
    });
    setErrors({});
  };
  useEffect(() => {
    if (item) {
      // When editing an item, set the form data and switch to edit mode
      setItemData(item);
      setIsEditMode(true);
    } else {
      // When opening for a new item, reset the form and switch to add mode
      clear();
      setIsEditMode(false);
    }
  }, [item, open]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // useEffect(() => {
  //   var checkId = user?.result?._id;
  //   if (checkId !== undefined) {
  //     setItemData({ ...itemData, itemId: [checkId] });
  //   } else {
  //     setItemData({ ...itemData, itemId: [user?.result?.googleId] });
  //   }
  // }, [location]);

  const handleClose = () => {
    setItemData({
      itemName: "",
      unitPrice: "",
      itemType: "",
      commission: {
        commissionType: "0",
        commissionValue: "",
      },
      itemId: [],
    });
    setErrors({});
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            paddingLeft: "20px",
            color: "white",
            backgroundColor: "#ef7f1a",
          }}
        >
          {itemId ? "Edit Activity" : "New Activity"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Activity"
                variant="outlined"
                className={classes.inputStyle}
                name="itemName"
                type="text"
                required
                size="small"
                onChange={handleChange}
                value={itemData.itemName}
                error={!!errors.itemName} // Check if error exists
                helperText={errors.itemName} // Display error message
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Unit Price"
                variant="outlined"
                className={classes.inputStyle}
                name="unitPrice"
                type="number"
                required
                size="small"
                onChange={handleChange}
                value={itemData.unitPrice}
                error={!!errors.unitPrice} // Check if error exists
                helperText={errors.unitPrice} // Display error message
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Commission"
                variant="outlined"
                className={classes.inputStyle}
                name="commission"
                type="number"
                size="small"
                onChange={handleChange}
                value={itemData?.commission?.commissionValue}
                error={!!errors?.commission} // Check if error exists
                helperText={errors?.commission}
                style={{ maxWidth: "130px" }} // Display error message
              />
              <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled elevation buttons"
                style={{ marginLeft: 10 }}
              >
                <Button
                  color={
                    itemData?.commission?.commissionType === "0"
                      ? "primary"
                      : "default"
                  }
                  onClick={() => handleCommissionTypeChange("0")}
                >
                  Flat
                </Button>
                <Button
                  color={
                    itemData?.commission?.commissionType === "1"
                      ? "primary"
                      : "default"
                  }
                  onClick={() => handleCommissionTypeChange("1")}
                >
                  %
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.inputStyle}
                size="small"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Type
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type-select"
                  name="itemType"
                  value={itemData.itemType}
                  onChange={handleChange}
                  label="Type"
                  error={!!errors.itemType}
                >
                  <MenuItem value="Extra Selling">Extra Selling</MenuItem>
                  <MenuItem value="Payable">Payable</MenuItem>
                </Select>
                {errors.itemType && (
                  <span style={{ color: "red" }}>{errors.type}</span>
                )}
              </FormControl>
              {/* <FormControl variant="outlined" className={classes.inputStyle}>
              <InputLabel id="demo-simple-select-outlined-label">
                    Type
                  </InputLabel>
                <Select
                  labelId="type-label"
                  id="type-select"
                  name="type"
                  value={itemData.type}
                  onChange={handleChange}
                  error={!!errors.type}
                >
                  <MenuItem value="Extra Selling">Extra Selling</MenuItem>
                  <MenuItem value="Payable">Payable</MenuItem>
                </Select>
                {errors.type && (
                  <span style={{ color: "red" }}>{errors.type}</span>
                )}
              </FormControl> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitItem}
            variant="contained"
            style={{
              marginRight: "25px",
              backgroundColor: "#ef7f1a",
              color: "white",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddItem;
