
const user = JSON.parse(localStorage.getItem('profile'))

export const initialState = {
    selling: [],
    payable:[],
    total: 0,
    paymentMode:'',
    pax:0,
    // notes: user?.userProfile?.paymentDetails,
    // rates: '',
    // vat: 0,
    // currency: '',
    invoiceNumber: Math.floor(Math.random() * 100000),
    // status: '',
    creator: '',
}
