import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {format} from "../../intl.js";
import { useSnackbar } from "react-simple-snackbar";
import PdfDownload from "../../components/svgIcons/PdfDownload";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: 20,
  },
  input: {
    marginRight: 10,
  },
  button: {
    margin: "10px 15px",
    height: 30,
  },
  boldRow: {
    fontWeight: "bold",
  },
  summaryBox: {
    width: "fit-content", // Adjust width to fit content
    margin: "0 ", // Center horizontally
    padding: 20,
    marginTop: 20, // Adjust margin top as needed
  },
});

const Statement = ({ invoices, receipts, client,history }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(getTodayDateString());
  const [endDate, setEndDate] = useState(getTodayDateString());
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [displayTable, setDisplayTable] = useState(false);
  // Sample data for demonstration
  const openInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  const downloadPdf = () => {
    console.log(dataWithBalance)
    setLoading(true);
    if (dataWithBalance.length) {
      setDownloadStatus("loading");
      axios
        .post(`${process.env.REACT_APP_API}/create-statement`, {
          dataWithBalance,
          startDate,
          endDate,
          client,
          totalDebit,
          totalCredit,
          openingBal: calOpeningBal(),
          lastRowCurrentBalance,
        })
        .then(() =>
          axios.get(`${process.env.REACT_APP_API}/fetch-statement`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          const currentDate = new Date();

          saveAs(pdfBlob, `Statement${client.name}${currentDate}.pdf`);
          const downloadUrl = `https://qit.mindtree.co.th/files/Exported-Statements/Statement.pdf`;
          window.open(downloadUrl, "_blank");
          setLoading(false);
          setDownloadStatus("success");
        });
    } else {
      openSnackbar("No record to Export");
    }
  };

  function calOpeningBal() {
    const filteredInvoices = invoices.filter(
      (invoice) => moment(invoice.date) < moment(startDate)
    );
    const filteredReceipts = receipts.filter(
      (r) =>
        moment(r.date) < moment(startDate) && r.transactionType === "Receipt"
    );
    const filteredPayments = receipts.filter(
      (r) =>
        moment(r.date) < moment(startDate) && r.transactionType === "Payment"
    );

    const invoicesTotal = filteredInvoices.reduce(
      (sum, invoice) => sum + invoice.total,
      0
    );

    const paymentsTotal = filteredPayments.reduce(
      (sum, receipt) => sum + receipt.payment,
      0
    );
    const receiptsTotal = filteredReceipts.reduce(
      (sum, receipt) => sum + receipt.payment,
      0
    );
   
    // console.log(paymentsTotal)
    // console.log(receiptsTotal)
    const openingBal = Math.abs(invoicesTotal + paymentsTotal - receiptsTotal);
    return openingBal;
  }

  const mergeAndSortData = () => {
    // Merge invoices and receipts arrays
    const mergedData = [...invoices, ...receipts];

    // Filter merged data based on the date range
    const filteredData = mergedData.filter((item) =>
      moment(item.date).isBetween(startDate, endDate, "days", "[]")
    );

    // Sort the filtered data by date
    const sortedData = filteredData.sort((a, b) =>
      moment(a.date).isBefore(b.date) ? -1 : 1
    );

    // console.log(sortedData);
    return sortedData;
  };

  function getTodayDateString() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad month and day with leading zeros if needed
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }
  const handleDateChange = (dateType, value) => {
    setDisplayTable(false);
    if (dateType === "start") {
      setStartDate(value);
    } else if (dateType === "end") {
      setEndDate(value);
    }
  };
  const handleGenerateStatement = () => {
    // Fetch data for the given date range from backend or process accordingly
    // For demonstration, here we'll just log the date range
    console.log("Statement generated for:", startDate, "to", endDate);
    // You can perform further actions like fetching data based on the provided date range

    // For now, just set displayTable to true to render the table
    setDisplayTable(true);
  };
  const sortedData = mergeAndSortData();

  const totalDebit = sortedData.reduce((sum, data) => {
    const commissionAmount = data.total || 0;
    const paymentAmount = data.transactionType === "Payment" ? data.payment : 0; // If payment is undefined, default to 0
    // If commission is undefined, default to 0
    sum += paymentAmount + commissionAmount;

    return sum;
  }, 0);
  const totalCredit = sortedData.reduce((sum, data) => {
    const receiptAmount = data.transactionType === "Receipt" ? data.payment : 0; // If payment is undefined, default to 0
    // If commission is undefined, default to 0
    sum += receiptAmount;

    return sum;
  }, 0);

  // The totalDebit value

  const calculateRunningBalance = () => {
    let runningBalance = calOpeningBal();
    const dataWithBalance = sortedData.map((row) => {
      if (row.invoiceNumber) {
        // Invoice row
        runningBalance += row.total;
      } else {
        if (row.transactionType === "Receipt") {
          runningBalance -= row.payment;
        } else {
          runningBalance += row.payment;
        }
      }
      return { ...row, currentBalance: runningBalance };
    });
    return dataWithBalance;
  };
  const dataWithBalance = calculateRunningBalance();

  const lastRowCurrentBalance =
    dataWithBalance.length > 0
      ? dataWithBalance[dataWithBalance.length - 1].currentBalance
      : calOpeningBal();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <TextField
          className={classes.input}
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => handleDateChange("start", e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.input}
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => handleDateChange("end", e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleGenerateStatement}
        >
          Generate Statement
        </Button>

        {loading ? (
          <CircularProgress style={{ marginTop: "20px", marginLeft: "10px" }} />
        ) : (
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            className={classes.button}
            onClick={downloadPdf}
          >
            <PictureAsPdfIcon />
          </IconButton>
        )}
      </div>
      {displayTable && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            {/* Card 1 */}
            <Card elevation={3} style={{ flex: 1 }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Opening Balance
                </Typography>
                <Typography>{format(calOpeningBal())}</Typography>
              </CardContent>
            </Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddIcon fontSize="Large"/>
            </div>
            {/* Card 2 */}
            <Card elevation={3} style={{ flex: 1 }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Total Debit
                </Typography>
                <Typography>{format(totalDebit)}</Typography>
              </CardContent>
            </Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RemoveIcon fontSize="Large"/>
            </div>
            {/* Card 3 */}
            <Card elevation={3} style={{ flex: 1 }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Total Credit
                </Typography>
                <Typography>{format(totalCredit)}</Typography>
              </CardContent>
            </Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KeyboardArrowRightIcon fontSize="Large"/>
            </div>

            {/* Card 4 */}
            <Card elevation={3} style={{ flex: 1 }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Current Balance
                </Typography>
                <Typography>{format(lastRowCurrentBalance)}</Typography>
              </CardContent>
            </Card>

            {/* + icon */}
          </div>

          <TableContainer component={Paper} className={classes.container}>
            <Table className={classes.table} aria-label="Statement Table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Particulars</TableCell>
                  <TableCell>Dr/Cr</TableCell>
                  <TableCell>Amount(฿)</TableCell>
                  <TableCell>Balance(฿)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {moment(startDate).format("Do-MMM-YYYY")}
                  </TableCell>
                  <TableCell>Opening Balance</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{calOpeningBal()}</TableCell>
                  <TableCell>{calOpeningBal()}</TableCell>
                </TableRow>
                {dataWithBalance.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(row.date).format("Do-MMM-YYYY")}
                    </TableCell>
                    <TableCell onClick={row.invoiceNumber?()=>openInvoice(row._id):null} style={row.invoiceNumber?{cursor:"pointer"}:{cursor:"inherit"}}>
                      {row.invoiceNumber
                        ? `Invoice-${row.invoiceNumber}`
                        : row.transactionType === "Receipt"
                        ? `Amt Received-${row._id}`
                        : `Amt Paid-${row._id}`}
                    </TableCell>
                    <TableCell>
                      {row.invoiceNumber
                        ? "Dr"
                        : row.transactionType === "Receipt"
                        ? "Cr"
                        : "Dr"}
                    </TableCell>
                    <TableCell>
                      {row.total ? row.total : row.payment}
                    </TableCell>

                    <TableCell>{row.currentBalance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default Statement;
