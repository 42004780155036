import React, { useState } from "react";
import Field from "./Field";
import useStyles from "./styles";
import { makeStyles,createTheme,ThemeProvider,useTheme } from "@material-ui/core/styles";
import styles from "./Login.module.css";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { signup, signin } from "../../actions/auth";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  useMediaQuery
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { createProfile } from "../../actions/profile";
import { useSnackbar } from "react-simple-snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  profilePicture: "",
  bio: "",
};
const colorTheme = createTheme({
  palette: {
    primary: {
      main: '#ef7f1a',
    },
  },
});
const Login = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [formData, setFormData] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
    
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
      isValid = false;
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required.";
      isValid = false;
    }

    if (isSignup && formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      //(validateForm())
      return;
    }
    if (isSignup) {
      dispatch(signup(formData, openSnackbar, setLoading));
    } else {
      dispatch(signin(formData, openSnackbar, setLoading));
    }
    setLoading(true);
  };

  const switchMode = () => {
    setIsSignup((prevState) => !prevState);
  };

  const googleSuccess = async (res) => {
    const result = jwtDecode(res.credential);
    const token = res?.credential;
    dispatch(
      createProfile({
        name: result?.name,
        email: result?.email,
        userId: result?.jti,
        phoneNumber: "",
        businessName: "",
        contactAddress: "",
        logo: result?.picture,
        website: "",
      })
    );

    try {
      dispatch({ type: "AUTH", data: { result, token } });

      window.location.href = "/todaysBookings";
    } catch (error) {
      //(error);
    }
  };
  const googleError = (error) => {
    //(error);
    //("Google Sign In was unseccassful. Try again later");
  };

  if (user) {
    history.push("/todaysBookings");
  }
 
  return (
    
    <Container component="main" maxWidth="xs"style={{
      minHeight:isMobile? "740px":"470px"
     
    }}>
        <ThemeProvider theme={colorTheme}>
      <Paper className={classes.paper} elevation={2}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography> */}
        <div className={styles.header2}>
          <img
            onClick={() => history.push("/")}
            src="https://mindtree.co.th/wp-content/uploads/mindtreetechnologies.svg"
            alt="Sadhana Ayurvedics"
            
          />
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {isSignup && (
              <>
                <Field
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                />
                <Field
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                />
              </>
            )}
             
            <Field
              name="email"
              label="User Name"
              handleChange={handleChange}
              type="email"
              error={errors.email}
            />
            <Field
              name="password"
              label="Password"
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
              error={errors.password}
            />
            {isSignup && (
              <Field
                name="confirmPassword"
                label="Repeat Password"
                handleChange={handleChange}
                type="password"
                error={errors.confirmPassword}
              />
            )}
          </Grid>
          <div className={styles.buttons}>
            <div>
              {/* <button className={styles.submitBtn}> { isSignup ? 'Sign Up' : 'Sign In' }</button> */}
              {/* <ProgressButton>{ isSignup ? 'Sign Up' : 'Sign In' }</ProgressButton> */}
              {loading ? (
                <CircularProgress   />
              ) : (
                <button className={styles.loginBtn}>
                  {isSignup ? "Sign Up" : "Login"}
                </button>
              )}
            </div>
            {/* <div className={styles.option}>
                  <span>or</span>
                </div>
                <div> 
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                      onSuccess={googleSuccess}
                      onError={googleError}
                      text='continue_with'
                      useOneTap
                      auto_select
                      state_cookie_domain='single_host_origin'
                    />
                  </GoogleOAuthProvider>
                </div> */}
          </div>
          <Grid container alignItems="center">
            {/* <Grid item>
              <Typography>
                {isSignup
                  ? "Already have an account?"
                  : "Don't have an account? "}
                   <Typography className={styles.switchBtn} onClick={switchMode}>
                {isSignup ? " Login" : "Signup"}
              </Typography>
              </Typography>
            </Grid> */}
           
          </Grid>

          <Link to="forgot">
            <p
              style={{
                textAlign: "center",
                color: "#1d7dd6",
                marginTop: "20px",
              }}
            >
              Forgot your Password?
            </p>
          </Link>
        </form>
      </Paper>
      </ThemeProvider>
    </Container>
  );
};

export default Login;
