
import React, { useState, useEffect,useContext} from 'react'
import { getAllItems, getItemsByUser } from '../../actions/itemActions'
import { ItemContext } from '../../Context/ItemContext'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'
import AddItem from './addItem'
import Items from './Items'
const ItemsList = () => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  // const [itemId, setItemId] = useState(null)
  const {itemId,setItemId}=useContext(ItemContext);
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('profile'))
  const {items} = useSelector((state) => state.items)
  console.log(items)
  const isLoading = useSelector(state => state.items.isLoading)// Assuming you have stored items in the Redux store
  
  useEffect(() => {
    dispatch(getAllItems())
    // dispatch(getItemsByUser({ search: user.result?._id || user.result.googleId }));
  },[location, dispatch])

  if(!user) {
    history.push('/login')
  }

  
  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

  if(items.length === 0) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px'}}>
      <NoData />
    <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>No items yet. Click the plus icon to add items</p>
  
    </div>
  }
  return (
 
      <div>
            <AddItem
                open={open} 
                setOpen={setOpen}
                itemId={itemId}
                setItemId={setItemId}
            />
            <Items
                open={open} 
                setOpen={setOpen}
                itemId={itemId}
                setItemId={setItemId}
                items={items}
            />
        </div>
 
  );
};

export default ItemsList;
