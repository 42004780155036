import * as api from '../api/index'

import { ADD_NEW_SUPPLIER, ALL_SUPPLIERS,UPDATE_SUPPLIER, DELETE_SUPPLIER, FETCH_SUPPLIERS_BY_USER, FETCH_SUPPLIER, START_LOADING, END_LOADING } from './constants'


export const getSupplier = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchSupplier(id);
      dispatch({ type: FETCH_SUPPLIER, payload: { supplier: data } });

    } catch (error) {
      console.log(error);
    }
  };

  export const getAllSuppliers = () => async (dispatch) => {
    try {
  
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchAllSuppliers();
    
      //  console.log(data.email)
      dispatch({ type: ALL_SUPPLIERS, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };
export const getSuppliersByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchSuppliersByUser(searchQuery)
      
      dispatch({ type: FETCH_SUPPLIERS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createSupplier =(supplier, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addSupplier(supplier)
        dispatch({ type: ADD_NEW_SUPPLIER, payload: data })
        openSnackbar("Supplier added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateSupplier =(id, supplier, openSnackbar) => async (dispatch) => {

    const { data } = await api.updateSupplier(id, supplier)
    dispatch({ type: UPDATE_SUPPLIER, payload: data })
    openSnackbar("Supplier updated successfully")
    try {
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteSupplier =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteSupplier(id)

        dispatch({type: DELETE_SUPPLIER, payload: id})
        openSnackbar("Supplier deleted successfully")
    } catch (error) {
        console.log(error)
    }
}