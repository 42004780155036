import React, { createContext, useState } from 'react';

export const ItemContext = createContext();

export const ItemProvider = ({ children }) => {
    const [itemId,setItemId]=useState(null);
  
  return (
    <ItemContext.Provider value={{itemId,setItemId }}>
      {children}
    </ItemContext.Provider>
  );
};
