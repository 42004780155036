import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ItemContext } from "../../Context/ItemContext";
import { useLocation, useHistory } from "react-router-dom";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddClient from "../Invoice/AddClient";
import AddSupplier from "../Suppliers/AddSupplier";
import MeetingForm from "../Meetings/MeetingForm";
import Meetings from "../Meetings/Meetings";
import { AppContext } from "../../Context/AppContext";
import { EditContext } from "../../Context/EditContext";
import { useContext } from "react";
import AddItem from "../Items/addItem";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {
  Check,
  Pie,
  Bag,
  Card,
  Clock,
  Frown,
  Order,
  Rupee,
  Report,
  SalesPerson,
  Item,
  Customer,
} from "../Dashboard/Icons";
const FabButton = () => {
  const location = useLocation();
  const mainButtonStyles = {
    backgroundColor: "#ef7f1a",
  };
  const [open, setOpen] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const { showForm, setShowForm } = useContext(AppContext);
  const { itemId, setItemId } = useContext(ItemContext);
  useEffect(() => {
    setShowForm(false);
    setOpen(false);
    setOpenItem(false);
  }, [location]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  // if(location.pathname === '/invoice') return null
  const handleAddMeeting = () => {
    //("handleAddMeeting CAlled");
    setShowForm(true);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };
  return (
    <div>
      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogContent>
          <MeetingForm onClose={handleCloseForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <AddClient setOpen={setOpen} open={open} />
      <AddSupplier setOpen={setOpenSupplier} open={openSupplier} />
      <AddItem setOpen={setOpenItem} open={openItem} />
      {!showForm && !open && !openItem && !openSupplier && (
        <Fab
          mainButtonStyles={mainButtonStyles}
          icon={<AddIcon />}
          alwaysShowTitle={true}
        
          event="click"
        >
          {location.pathname !== "/invoice" && (
            <Action
              text="Add Booking"
              // onClick={() =>  history.push(`/invoice`)}
              onClick={() => (window.location.href = "/invoice")}
              style={{ backgroundColor: "#ef7f1a" }}
            >
              <Order />
            </Action>
          )}

          <Action
            text="Add Agent/Hotel"
            onClick={() => setOpen((prev) => !prev)}
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <Customer />
          </Action>
          {/* <Action
            text="Agents/Hotels"
            onClick={() => (window.location.href = "/customers")}
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <Customer />
          </Action> */}
          {/* <Action
            text="Bookings"
            onClick={() => (window.location.href = "/invoices")}
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <Report />
            <Customer />
          </Action> */}
          {user?.result?.userType === "admin" && (
              <Action
                text="Add Activity"

                onClick={() =>{ setItemId(null);
                  setOpenItem((prev) => !prev)} }
                style={{ backgroundColor: "#ef7f1a" }}
              >
                <Item />
              </Action>
            )}
          {/* <Action
            text="Payment/Receipt"
            // onClick={() => setShowForm((prev) => !prev)}
            onClick={() =>history.push(`/meetings`) }
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <AccountBalanceIcon style={{color:"Black"}} />
          </Action> */}
          <Action
             text="Add Payment/Receipt"
             onClick={() => setShowForm((prev) => !prev)}
            // onClick={() =>history.push(`/meetings`) }
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <AccountBalanceIcon style={{color:"Black"}} />
          </Action>
          {/* <Action
            text="Activities"
            onClick={() => history.push(`/ItemsList`)}
            // onClick={handleAddMeeting}
            style={{ backgroundColor: "#ef7f1a" }}
          >
            <Report />
          </Action> */}
        </Fab>
      )}
    </div>
  );
};

export default FabButton;
