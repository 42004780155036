import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, InputBase, Typography, FormControl, TextField } from '@material-ui/core';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import Autocomplete from "@material-ui/lab/Autocomplete";
const ActivityTable = ({ classes, invoiceData, handleRemoveField, handleChange, handleNameChange, inputRef, items, handleAddField,styles }) => {

  return (
    <div>
      <TableContainer component={Paper} className="tb-container">
        <Table className={classes.table} aria-label="simple table">
        <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "20px" }}>Delete</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Activities</TableCell>

                  <TableCell style={{ fontSize: "20px" }}>PAX</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Commission</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Price</TableCell>
                  <TableCell align="center" style={{ fontSize: "20px" }}>
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData?.map((itemField, index) => (
                  // console.log("itemField:", itemField), // Log the itemField object
                  // console.log("itemName:", itemField.itemName), // Log the itemName value
                  <TableRow key={index}>
                    <TableCell align="left">
                      <IconButton
                        style={{ border: "1px solid red" }}
                        onClick={() => handleRemoveField(index)}
                      >
                        <DeleteOutlineRoundedIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {invoiceData[index].itemName}
                      <div
                        style={
                          invoiceData[index].itemName
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            options={items}
                            getOptionLabel={(item) => item.itemName}
                            value={items.itemName}
                            onChange={(e, value) => {
                              handleNameChange(index, value || "");
                              inputRef.current.focus();
                            }} // Modify this line
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search Activity"
                                fullWidth
                                size="small"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </TableCell>

                    {/* <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {itemField.itemName ? (
                        <span>
                         
                          {itemField.weight}
                        </span>
                      ) : (
                        0
                      )}
                    </TableCell> */}
                    <TableCell align="left" style={{ fontSize: "18px" }}>
                      {" "}
                      <InputBase
                        inputRef={inputRef}
                        sx={{ ml: 1, flex: 1, width: "10px" }}
                        type="number"
                        name="quantity"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.itemName ? itemField.quantity : "0"}
                        placeholder="0"
                        style={{ fontSize: "18px", width: "30px" }}
                        // autoFocus={itemField.itemName }
                      />{" "}
                    </TableCell>
                    
                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {itemField.itemName ? (
                        <span>
                          {/* {getItemPrice(invoiceData.items[index].itemName)} */}
                          {itemField?.commission?.commissionType==="1"?itemField?.commission?.commissionValue/100*itemField.unitPrice:itemField?.commission?.commissionValue}
                        </span>
                      ) : (
                        0
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {itemField.itemName ? (
                        <span>
                          {/* {getItemPrice(invoiceData.items[index].itemName)} */}
                          {itemField.unitPrice}
                        </span>
                      ) : (
                        0
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="amount"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.quantity * itemField.unitPrice}
                        disabled
                        style={{ fontSize: "18px", display: "none" }}
                      />{" "}
                      <Typography variant="h5" color="initial">
                        {itemField.quantity * itemField?.commission?.commissionValue}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.addButton}>
        <button
          onClick={handleAddField}
          style={{ backgroundColor: "#ef7f1a", color: "white" }}
          // disabled={scanning}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default ActivityTable;
