import * as api from '../api/index'

import { ADD_NEW, UPDATE, DELETE, GET_INVOICE, FETCH_INVOICE_BY_USER, FETCH_ALL,START_LOADING, END_LOADING,FETCH_PAGE } from './constants'

export const getInvoices = () => async (dispatch)=> {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.fetchInvoices()
        console.log(data)
        dispatch({ type: FETCH_ALL, payload: data })
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}
export const getInvoicesByPage = (page,limit) => async (dispatch)=> {
    try {
        debugger
        dispatch({ type: START_LOADING })
        const { data } = await api.fetchInvoicesByPage(page,limit)
        console.log(data)
        dispatch({ type: FETCH_PAGE, payload: data })
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}
export const getInvoicesByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchInvoicesByUser(searchQuery)
      dispatch({ type: FETCH_INVOICE_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const getInvoice = (id) => async (dispatch)=> {

    const user = JSON.parse(localStorage.getItem('profile'))

    try {
        const { data } = await api.fetchInvoice(id)
       // console.log("getInvoicedata",data)
        const businessDetails = await api.fetchProfilesByUser(user.result._id)
       // console.log("Business Details: " ,businessDetails)
        const invoiceData = {...data, businessDetails}

       // console.log("invoice Data",invoiceData)
        dispatch({ type: GET_INVOICE, payload: invoiceData  })
    } catch (error) {
        console.log(error.response)
    }
}

export const createInvoice =(invoice, history) => async (dispatch) => {
    try {
        console.log("Create Invoice Called")
        dispatch({ type: START_LOADING })
        const { data } = await api.addInvoice(invoice)
        console.log("Created Invoice Data",data)
        dispatch({ type: ADD_NEW, payload: data })
        history.push(`/invoice/${data._id}`)
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const updateInvoice =(id, invoice) => async (dispatch) => {

    try {
        const { data } = await api.updateInvoice(id, invoice)
        dispatch({ type: UPDATE, payload: data })
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteInvoice =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteInvoice(id)

        dispatch({type: DELETE, payload: id})
        openSnackbar("Order deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}