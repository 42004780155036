import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);
  const [QtydialogOpen, setQtyDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Define methods to interact with cartItems
  const handleAddToCart = (item) => {
    const updatedCartItems = [...cartItems];
    const existingItem = updatedCartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      existingItem.quantity++;
    } else {
      updatedCartItems.push({ ...item, quantity: 1 });
    }

    setCartItems(updatedCartItems);
  };

  const removeFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
  };

  const incrementQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
    // handleCloseQtyDialog();
  };

  const decrementQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
    }
    setCartItems(updatedCartItems);
    // handleCloseQtyDialog()
  };
  const handleQuantityChange = (event, index) => {
    const updatedCartItems = [...cartItems];
    var newQuantity = event.target.value;
  
    // if (isNaN(newQuantity) || event.target.value === '') {
    //   // If the input is not a valid number or empty, set the quantity to 0
    //   updatedCartItems[index].quantity = 1;
    // } else {
    //   // Otherwise, update the quantity, ensuring it's at least 1
    //   updatedCartItems[index].quantity = Math.max(newQuantity, 1);
    // }
    // if (isNaN(newQuantity) || event.target.value === '') {
    //   newQuantity="";
    //    }
    updatedCartItems[index].quantity = newQuantity;
    setCartItems(updatedCartItems); // Update the cartItems with the new quantity
  };
  
  
  
  const handleOpenQtyDialog = (index) => {
    console.log("handleOpenQtyDialog index: " + index);
    setSelectedIndex(index);
    setQtyDialogOpen(true);
   
  };
  const handleCloseQtyDialog = () => {
    setQtyDialogOpen(false);
  };
  const getTotalPrice = () => {
    let total = 0;

    for (const item of cartItems) {
      total += item.quantity * item.price;
    }

    return total;
  };
  const calculatePrice = (quantity, price) => (quantity * price).toFixed(2);
  return (
    <CartContext.Provider
      value={{
        cartItems,
        QtydialogOpen,
        selectedIndex,
        handleAddToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        calculatePrice,
        getTotalPrice,
        handleOpenQtyDialog,
        handleCloseQtyDialog,
        handleQuantityChange
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
