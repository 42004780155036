import React, { useState, useEffect } from "react";
import { getProfile } from "../actions/profile";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import {Avatar} from "@material-ui/core";
import Uploader from "../components/Settings/Form/Uploader";
const EditProfileForm = ({ profile, onUpdateProfile, onCloseDialog }) => {
  const [formData, setFormData] = useState({
    logo: profile.logo,
    email: profile.email,
    phoneNumber: profile.phoneNumber,
    // businessName: profile.businessName,
    contactAddress: profile.contactAddress,
    // paymentDetails: profile.paymentDetails,
  });
  const user = JSON.parse(localStorage.getItem("profile"));
  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the update profile function and pass the updated data
    onUpdateProfile(formData);
    // Close the dialog
    onCloseDialog();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "solid 1px #dddddd",
          paddingBottom: "20px",
        }}
      >
        <Avatar
          style={{
            width: "100px",
            height: "100px",
            margin: "5px",
            backgroundColor: "white",
          }}
          src={profile?.logo}
          alt=""
        />
      </div>
      <form onSubmit={handleSubmit}>
        <Uploader form={formData} setForm={setFormData} />
        {user?.email !== "admin@admin.com" ? null : (
        <TextField
          name="email"
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          
        />)}
        <TextField
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {/* <TextField
          name="businessName"
          label="Business Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.businessName}
          onChange={handleChange}
        /> */}
        <TextField
          name="contactAddress"
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.contactAddress}
          onChange={handleChange}
        />
        {/* <TextField
          name="paymentDetails"
          label="Payment Details"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.paymentDetails}
          onChange={handleChange}
        /> */}
        {/* Add submit and cancel buttons */}
        <Button type="submit" color="primary">
          Update Details
        </Button>
        <Button color="primary" onClick={onCloseDialog}>
          Cancel
        </Button>
      </form>
    </div>
  );
};

export default EditProfileForm;
