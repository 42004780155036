import React, { useState, useEffect } from "react";
import { useLocation, Link,withRouter } from "react-router-dom";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import "./Navbar.css"
const NavBar = () => {
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  
  // console.log(user.result.userType);
  useEffect(() => {
   
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  if (!user) return null;

  return (
    
    <div>
      {location.pathname !== "/PosMainScreen" &&(
      <nav className="navbar" style={{position:"fixed"}}>
        <ul className="navbar-nav">
          <li className="logo">
            <Link to="/dashboard" className="nav-link">
              <span className="link-text logo-text">
                <img
                  style={{ width: "100px",padding: "10px",marginTop: "10px" }}
                  src="https://mindtree.co.th/wp-content/uploads/mindtreetechnologies.svg"
                  alt="arc-invoice"
                />
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-pie-chart"
              >
                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
              </svg>
              <span className="link-text">Dashboard</span>
            </Link>
          </li> 

        {/* <li className="nav-item">
            <Link to="/invoice" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              <span className="link-text">Add Booking</span>
            </Link>
          </li>  */}
          <li className="nav-item">
            <Link to="/invoices" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-layers"
              >
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
              <span className="link-text">Bookings</span>
            </Link>
          </li>
           <li className="nav-item">
            <Link to="/meetings" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                stroke="currentColor"
              >
                <path d="M 7 2 C 5.3550302 2 4 3.3550302 4 5 L 4 19 C 4 20.64497 5.3550302 22 7 22 L 20 22 L 20 20 L 7 20 C 6.4349698 20 6 19.56503 6 19 C 6 18.43497 6.4349698 18 7 18 L 20 18 L 20 16 L 20 2 L 16 2 L 10 2 L 7 2 z M 7 4 L 10 4 L 10 12.123047 L 11.574219 11.019531 L 13 10.017578 L 16 12.123047 L 16 4 L 18 4 L 18 16 L 7 16 C 6.6481575 16 6.3148915 16.07394 6 16.1875 L 6 5 C 6 4.4349698 6.4349698 4 7 4 z M 12 4 L 14 4 L 14 8.2773438 L 13 7.5761719 L 12 8.2773438 L 12 4 z" />
              </svg>
              <span className="link-text">Payments/Receipts</span>
            </Link>
          </li> 
        
            {/* {user?.result?.userType === "admin" && (
            <li className="nav-item">
              <Link to="/adminDashboard" className="nav-link">
                
                <GroupAddIcon/>
                <span className="link-text">Sales Person</span>
              </Link>
            </li>
          )}  */}
          <li className="nav-item">
            <Link to="/customers" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-users"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span className="link-text">Agent/Hotels</span>
            </Link>
          </li>
          {/* {user?.result?.userType === "admin" && (
          <li className="nav-item">
            <Link to="/suppliers" className="nav-link">
              <PersonAddIcon/>
              <span className="link-text">Suppliers</span>
            </Link>
          </li>)} */}
          {user?.result?.userType === "admin" && ( <li className="nav-item">
            <Link to="/ItemsList" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="24px"
                height="24px"
                stroke="currentColor"
              >
                <path d="M 1 3 L 1 15 L 3 15 L 3 48 L 47 48 L 47 15 L 49 15 L 49 3 Z M 3 5 L 47 5 L 47 13 L 3 13 Z M 5 15 L 45 15 L 45 46 L 5 46 Z M 17.5 19 C 15.578125 19 14 20.578125 14 22.5 C 14 24.421875 15.578125 26 17.5 26 L 32.5 26 C 34.421875 26 36 24.421875 36 22.5 C 36 20.578125 34.421875 19 32.5 19 Z M 17.5 21 L 32.5 21 C 33.339844 21 34 21.660156 34 22.5 C 34 23.339844 33.339844 24 32.5 24 L 17.5 24 C 16.660156 24 16 23.339844 16 22.5 C 16 21.660156 16.660156 21 17.5 21 Z" />
              </svg>
              <span className="link-text">Activities</span>
            </Link>
          </li>
          )}
          {/* <li className="nav-item">
      <a href="#" className="nav-link">
        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="space-shuttle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="svg-inline--fa fa-space-shuttle fa-w-20 fa-5x">
          <g className="fa-group">
            <path fill="currentColor" d="M32 416c0 35.35 21.49 64 48 64h16V352H32zm154.54-232h280.13L376 168C243 140.59 222.45 51.22 128 34.65V160h18.34a45.62 45.62 0 0 1 40.2 24zM32 96v64h64V32H80c-26.51 0-48 28.65-48 64zm114.34 256H128v125.35C222.45 460.78 243 371.41 376 344l90.67-16H186.54a45.62 45.62 0 0 1-40.2 24z" className="fa-secondary" />
            <path fill="currentColor" d="M592.6 208.24C559.73 192.84 515.78 184 472 184H186.54a45.62 45.62 0 0 0-40.2-24H32c-23.2 0-32 10-32 24v144c0 14 8.82 24 32 24h114.34a45.62 45.62 0 0 0 40.2-24H472c43.78 0 87.73-8.84 120.6-24.24C622.28 289.84 640 272 640 256s-17.72-33.84-47.4-47.76zM488 296a8 8 0 0 1-8-8v-64a8 8 0 0 1 8-8c31.91 0 31.94 80 0 80z" className="fa-primary" />
          </g>
        </svg>
        <span className="link-text">Shuttle</span>
      </a>
    </li> */}
    
          <li className="nav-item" id="themeButton">
            <a href="/settings" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-settings"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
              </svg>
              <span className="link-text">Settings</span>
            </a>
          </li>
        </ul>
      </nav>
      )}
    </div>
  );
  
};

export default NavBar;
