import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showForm, setShowForm] = useState(false);
  
  return (
    <AppContext.Provider value={{ showForm, setShowForm }}>
      {children}
    </AppContext.Provider>
  );
};
