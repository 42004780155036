import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import axios from "axios";
import { EditContext } from "../../Context/EditContext";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
// import { toCommas } from "../../utils/utils";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getClientsByUser, getAllClients } from "../../actions/clientActions";
import { Container } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddClient from "../Invoice/AddClient";
import {
  createMeeting,
  getMeeting,
  updateMeeting,
} from "../../actions/meetingActions";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  dialogTitle: {
    backgroundColor: "#ef7f1a", // Replace 'primary.main' with your desired blue color
    color: "#fff", // White text color
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
}));

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});
const MeetingForm = ({ onClose}) => {
  const classes = useStyles();
  const [client, setClient] = useState(null);
  const [touched, setTouched] = useState(false);
  const { currId, setCurrId } = useContext(EditContext);
  const location=useLocation();
  const user = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const history = useHistory();

  const clients = useSelector((state) => state.clients.clients);
  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);
  //(clients);
  // const { meeting } = useSelector((state) => state.meetings);
  const [advance, setAdvance] = useState({
    cash: "",
    cheque: "",
  });
  const meeting = useSelector((state) =>
    currId ? state.meetings.meetings.find((c) => c._id === currId) : null
  );
  // //(meeting);

  const [formData, setFormData] = useState({
    remarks: "",
    payment: "",
    date: new Date(),
    paymentMode: "Cash",
    transactionType:"Payment"
  });
  const initialFormData = {
    remarks: "",
    payment: "",
    date: new Date(),
    paymentMode: "Cash",
    transactionType:"Payment"
    // Add other form fields and their initial values here
  };
  const [errors, setErrors] = useState({
    remarks: "",
    payment: "",
    client: "",
    paymentMode: "",
    transactionType:"",
  });

  // const handleFieldChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    

    // Clear the error message for the changed field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // const handleTime = (time) => {
  //   //(time);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     time: time,
  //   }));
  // };
  const handleDate = (date) => {
    //();
    const formattedDate = date.toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: formattedDate,
    }));
  };
  const handleClientChange = () => {
    setTouched(true);
    setErrors((prevErrors) => ({
      ...prevErrors,
      client: "",
    }));
  };
  const handleCancel = () => {
    setFormData({
      remarks: "",
      payment: "",
      date: new Date(),
      paymentMode: "Cash",
      transactionType:"Payment"
    });
    onClose();
    setCurrId(null);
  };
  useEffect(() => {
    if (meeting) {
      setFormData(meeting);
      setClient(meeting.client);
      // setAdvance(meeting.advance);
    }
  }, [meeting]);

  
 
  useEffect(() => {
    // Add a "beforeunload" event listener to prompt the user before leaving the page
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    // Check if the form has unsaved changes and display a confirmation message
    if (isFormDirty()) {
      event.preventDefault();
      event.returnValue =
        "You have unsaved changes. Are you sure you want to leave?";
    }
  };
  useEffect(() => {
    const unblock = history.block((location, action) => {
      // Check if the form has unsaved changes
      if (isFormDirty()) {
        // Prompt the user for confirmation when navigating to a different route
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    });

    return () => {
      unblock(); // Unregister the route change listener when the component unmounts
    };
  }, [history]);
  const isFormDirty = () => {
    // Compare the current formData with the initialFormData
    return (
      formData.remarks !== initialFormData.remarks ||
      formData.payment !== initialFormData.payment ||
      formData.date !== initialFormData.date ||
      formData.paymentMode !== initialFormData.paymentMode||
      formData.transactionType !== initialFormData.transactionType
      // Add more fields to check as needed
      // For example, if you have additional fields in your form, include them here
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger
    const requiredFields = ["date", "paymentMode", "payment",];
    const fieldErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        fieldErrors[field] = "This field is required.";
      }
    });
    // //("Client", client);
    if (!client ||client===null) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        client: "Agent/Hotel name is required.",
      }));
      return;
    }


    setErrors(fieldErrors);

    // If there are any errors, stop the form submission
    if (Object.keys(fieldErrors).length > 0) {
      return;
    }
    if (currId) {
      dispatch(
        updateMeeting(currId, {
          ...formData,
          client,
        
        })
      );
      // history.push(`/meeting/${meeting._id}`);
    } else {
      //("in create New");
      console.log("Form Data", formData);
      //("Advances", advance);

      dispatch(
        createMeeting({
          ...formData,
          client,
          creator: [user?.result?._id || user?.result?.googleId],
          createdAt: formData.date,
          // history
        })
      );
    }
    setFormData({
      remarks: "",
      payment: "",
      date: new Date(),
      paymentMode: "Cash",
    });
    setCurrId(null);
    onClose();
  };
  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => `${option.name}|${option.agentCode}`,
  };
  const [open, setOpen] = useState(false);


  return (
    <>
      <AddClient setOpen={setOpen} open={open} />

      <Dialog
        open
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCancel();
          }
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitle}
        >
          {currId ? "Edit Payment/Receipt" : "Add Payment/Receipt"}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>

          <form onSubmit={handleSubmit}>
            <ThemeProvider theme={colorTheme}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Date"
                      name="date"
                      value={formData.date}
                      onChange={handleDate}
                      required
                      fullWidth
                      format="dd/MM/yyyy"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Container>
                    <Typography
                      variant="overline"
                      style={{ color: "gray", paddingRight: "3px" }}
                      gutterBottom
                    >
                      Agent/Hotel Name
                    </Typography>

                    {client && (
                      <>
                        <Typography variant="subtitle1" gutterBottom>
                          {client.name}
                        </Typography>

                        <Typography variant="body2">{client.agentCode}</Typography>
                        <Typography variant="body1">{client.phone}</Typography>
                        <Button
                          color="primary"
                          size="small"
                          style={{
                            textTransform: "none",
                            backgroundColor: "#ef7f1a",
                            color: "white",
                          }}
                          onClick={() => setClient(null)}
                        >
                          Change
                        </Button>
                      </>
                    )}
                    <div
                      style={
                        client ? { display: "none" } : { display: "block" }
                      }
                    >
                      <Autocomplete
                        {...clientsProps}
                        // PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required={!invoice && true}
                            label="Select Agent/Hotel"
                            margin="normal"
                            variant="outlined"
                            error={touched && !!errors.client}
                            helperText={touched && errors.client}
                          />
                        )}
                        value={clients?.name}
                        onChange={(event, value) => {
                          setClient(value);
                          handleClientChange();
                          console.log(value)
                        }}
                        // onChange={(event, value) => setClient(value) }
                      />
                    </div>
                    {!client && (
                      <>
                        <Grid item style={{ paddingBottom: "10px" }}>
                          <Chip
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor: "#ef7f1a",
                                  color: "white",
                                }}
                              >
                                +
                              </Avatar>
                            }
                            label="New Agent/Hotel"
                            onClick={() => setOpen(true)}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                  </Container>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  
                </Grid>
                <Grid item xs={12}>
                  <FormLabel labelplacement="start">Payment Mode</FormLabel>
                  <RadioGroup
                    name="paymentMode"
                    value={formData.paymentMode}
                    onChange={handleFieldChange}
                    row
                  >
                    <FormControlLabel
                      value="Cash"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Cash"
                    />
                    <FormControlLabel
                      value="Online"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Online"
                    />
                    <FormControlLabel
                      value="Card"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Card"
                    />
                  </RadioGroup>
                  {!!errors.paymentMode && (
                    <Typography variant="body2" color="error">
                      {errors.paymentMode}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormLabel labelplacement="start">Transaction Type</FormLabel>
                  <RadioGroup
                    name="transactionType"
                    value={formData.transactionType}
                    onChange={handleFieldChange}
                    row
                  >
                    <FormControlLabel
                      value="Payment"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Payment"
                    />
                    <FormControlLabel
                      value="Receipt"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Receipt"
                    />
                  </RadioGroup>
                  {!!errors.transactionType && ( // Display error message conditionally
                    <Typography variant="body2" color="error">
                      {errors.transactionType}
                    </Typography>
                  )}
                </Grid>

                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Amount"
                      name="payment"
                      type="number"
                      value={formData.payment}
                      onChange={handleFieldChange}
                      fullWidth
                      className={classes.formField}
                      variant="outlined"
                      size="small"
                      error={!!errors.payment} // Set error prop based on error existence
                      helperText={errors.payment} // Display error message
                    />
                  </Grid>
                </>

                <Grid item xs={12}>
                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleFieldChange}
             
                    fullWidth
                    multiline
                    maxRows={4}
                    className={classes.formField}
                    variant="outlined"
                    size="small"
                    error={!!errors.remarks} // Set error prop based on error existence
                    helperText={errors.remarks} // Display error message
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </form>
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          <Button
            type="submit"
            variant="contained"
            // color="primary"
            style={{ backgroundColor: "#ef7f1a", color: "white" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MeetingForm;
