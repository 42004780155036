import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { deleteProfile, getProfiles, getProfile ,updateProfile} from "../actions/profile";
import { Avatar, Paper, Grid, Container } from "@material-ui/core";
import AdminProfileDetail from "./adminProfileDetail";
import { getUsers, deleteUser } from "../actions/auth";
import EditProfileForm from "./editProfileForm";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import AdminLogin from "./adminLogin/adminLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    overflowX: "auto",
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  addButton: {
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(26),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "150vh",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(2),
    border: "solid 1px #bcbcbc",
    // backgroundColor: '#EEEEEE'
  },
}));
const AdminProfiles = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));
  // console.log(user)
  const { profiles } = useSelector((state) => state.profiles);
  // const  profile  = useSelector((state) => state.profile);
 console.log(profiles)
  //   const { users } = useSelector((state) => state.auth);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [openSnackbar, closeSnackbar] = useSnackbar();
  //   console.log(users);
  // console.log(profiles)
  useEffect(() => {
    dispatch(getProfiles());
    // dispatch(getProfile(  user?.userProfile?._id ))
  }, [location]);

  useEffect(() => {
    dispatch(getUsers());
  }, [location]);

  const handleOpenDialog = (profile_Id) => {
    setSelectedProfile(profile_Id);
    // dispatch(getProfile(profile_Id));
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const handleUpdateProfile = (formData) => {
      dispatch(updateProfile(selectedProfile, formData, openSnackbar));
    setOpenDialog(false);
  };
  //   const handleDeleteUser = (id) => {
  //     dispatch(deleteUser(id, openSnackbar))
  //     console.log(id);
  //     dispatch(deleteProfile(id, openSnackbar))
  //   };
  return (
    <div className={classes.root}>
      <Typography variant="h3">Profiles</Typography>
     
      <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((profile, index) => (
            <TableRow key={profile._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{profile.name}</TableCell>
              <TableCell>{profile.email}</TableCell>
              <TableCell>{profile.phoneNumber}</TableCell>
              <TableCell>{profile.contactAddress}</TableCell>
              {/* <TableCell>
                {profile?.userType === "admin" ? "admin" : "user"}
              </TableCell> */}
              <TableCell>
                <div className={classes.buttonGroup}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleOpenDialog(profile._id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      dispatch(deleteProfile(profile._id, openSnackbar));
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {profiles &&
            profiles.map((profile) => {
              if (profile._id === selectedProfile) {
                return (
                  <EditProfileForm
                    key={profile._id}
                    profile={profile}
                    onUpdateProfile={handleUpdateProfile}
                    onCloseDialog={handleCloseDialog}
                  />
                );
              }
            })}
        </DialogContent>
      </Dialog>
    </div>
    // <>
    // {profiles.email}
    // <Container component="main" maxWidth="sm">
    //     <Paper className={classes.paper} elevation={0} >
    //     <AdminProfileDetail  profiles={profiles} />
    //     {/* <Button variant="outlined" style={{margin: '30px', padding: '15px 30px'}} onClick={() => setSwitchEdit(1)}>Edit Profile</Button> */}
    //    </Paper>
    //    </Container>
    // </>
  );
};

export default AdminProfiles;
