
import { ALL_SUPPLIERS, ADD_NEW_SUPPLIER, UPDATE_SUPPLIER, DELETE_SUPPLIER, FETCH_SUPPLIERS_BY_USER, FETCH_SUPPLIER, START_LOADING, END_LOADING } from '../actions/constants'

const suppliers = (state = { isLoading: true, suppliers: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_SUPPLIERS:
        return {
          ...state,
          suppliers: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_SUPPLIERS_BY_USER:
        return { ...state, suppliers: action.payload };

      case FETCH_SUPPLIER:
        return { ...state, suppliers: action.payload.supplier };
      case ADD_NEW_SUPPLIER:
        return { ...state, suppliers: [...state.suppliers, action.payload] };
      case UPDATE_SUPPLIER:
        return { ...state, suppliers: state.suppliers.map((supplier) => (supplier._id === action.payload._id ? action.payload : supplier)) };
      case DELETE_SUPPLIER:
        return { ...state, suppliers: state.suppliers.filter((supplier) => supplier._id !== action.payload) };
      default:
        return state;
    }
  };

  export default suppliers




//   const suppliers =(suppliers =[], action) => {
//     switch (action.type) {
//         case ALL_SUPPLIERS:
//             return action.payload

//         case FETCH_SUPPLIERS_BY_USER:
//             return action.payload

//         case ADD_NEW_SUPPLIER:
//             return [...suppliers, action.payload]

//         case UPDATE_SUPPLIER:
//             return suppliers.map((supplier) => supplier._id === action.payload ? action.payload : supplier)
        
//         case DELETE_SUPPLIER: 
//         return suppliers.filter((supplier) => supplier._id !== action.payload)
        
//         default:
//             return suppliers;
//     }
// }

// export default suppliers
