
export const FETCH_ALL = "FETCH_ALL"
export const FETCH_PAGE = "FETCH_PAGE"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"



export const FETCH_ALL_MEETINGS = "FETCH_ALL_MEETINGS"
export const ADD_NEW_MEETING = "ADD_NEW_MEETING"
export const UPDATE_MEETING = "UPDATE_MEETING"
export const DELETE_MEETING = "DELETE_MEETING"
export const GET_MEETING = "GET_MEETING"
export const FETCH_MEETING_BY_USER = "FETCH_MEETING_BY_USER"



export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_PROFILE_USER';


export const ALL_SUPPLIERS = "ALL_SUPPLIERS"
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER"
export const DELETE_SUPPLIER = "DELETE_SUPPLIER"
export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER"
export const FETCH_SUPPLIER_BY_USER = 'FETCH_SUPPLIER_USER';
export const FETCH_SUPPLIERS_BY_USER = 'FETCH_SUPPLIERS_USER';
export const FETCH_SUPPLIER = "FETCH_SUPPLIER"

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const DELETE_USER='DELETE_USER';
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_BY_USER';
export const FETCH_PROFILES_USER = 'FETCH_PROFILES_USER';



export const ADD_NEW_ITEM= 'ADD_NEW_ITEM';
export const UPDATE_ITEM='UPDATE_ITEM';
export const DELETE_ITEM='DELETE_ITEM';
export const FETCH_ITEMS='FETCH_ITEMS';
export const FETCH_ITEMS_USER='FETCH_ITEMS_USER';