import React, {useState } from 'react';
import { useDispatch } from 'react-redux'
import styles from './Suppliers.module.css'
// import moment from 'moment'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'react-simple-snackbar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteSupplier } from '../../actions/supplierActions';
import { useMediaQuery } from "@material-ui/core";
// import supplier from '../../supplier.json'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),

  },
}));

function TablePaginationActions(props) {

  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: '16px'
}
}));


const Supplier = ({ setOpen, setCurrentId, suppliers }) => {
    
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
      // eslint-disable-next-line 
      const [openSnackbar, closeSnackbar] = useSnackbar()
  const history=useHistory();
  const dispatch = useDispatch()
  
  const rows = suppliers
  
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleSupplierNameClick = (id) => {
  //   history.push(`/stats/${id}`);
  // };
  const handleEdit = (selectedInvoice) => {
    
    setOpen((prevState) => !prevState)
    setCurrentId(selectedInvoice)
    
  }
  const handleDelete= (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this client?');
  if(confirmDelete){
    dispatch(deleteSupplier(id, openSnackbar))
  }
  }

  const tableStyle = { width: 160, fontSize: 14, cursor: 'pointer', borderBottom: 'none',  padding: '8px', textAlign: 'center' }
const headerStyle = { borderBottom: 'none', textAlign: 'center'}
const filteredRows = rows.filter((row) =>
row.name.toLowerCase().includes(searchQuery.toLowerCase())
);

  return (
    <div className={styles.pageLayout}>
   <Container style={{ width: isMobile ? "100%":"85%"}}>
    <TextField
            id="search-bar"
            label="Search"
            variant="outlined"
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
           
          />
    {/* <Typography variant="h2" align="center" gutterBottom style={{ marginTop: "2px", marginBottom: "10px" }}>
    Suppliers
      </Typography> */}
        <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="custom pagination table">

      <TableHead>
          <TableRow>
            <TableCell style={{...headerStyle, width: '10px'}}>Number</TableCell>
            <TableCell style={headerStyle}>Supplier Name</TableCell>
            <TableCell style={headerStyle}>Contact Person</TableCell>
            <TableCell style={headerStyle}>Phone</TableCell>
            <TableCell style={headerStyle}>Edit</TableCell>
            <TableCell style={headerStyle}>Delete</TableCell>
            
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredRows
          ).map((row, index) => (
            <TableRow key={row._id} style={{cursor: 'context-menu'}} >
              <TableCell style={{...tableStyle, width: '10px',cursor: 'context-menu'}}>{index + 1}</TableCell>
              <TableCell  style={tableStyle} scope="row" > <Button style={{textTransform: 'none'}} 
              //  onClick={() => handleSupplierNameClick(row._id)}
               > {row.name} </Button></TableCell>
               
              <TableCell style={tableStyle}>{row.contactPerson}</TableCell>
              <TableCell style={tableStyle}>{row.phone}</TableCell>
              <TableCell style={{...tableStyle, width: '10px'}}>
                  <IconButton onClick={() => handleEdit(row._id)}>
                    <BorderColorIcon style={{width: '20px', height: '20px'}} />
                  </IconButton>
              </TableCell>
              <TableCell style={{...tableStyle, width: '10px'}}> 
              <IconButton onClick={() => handleDelete(row._id)} disabled={row.name === "Sadhana Ayurvedics Pvt. Ltd."}>
                    <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}} />
                  </IconButton>
              </TableCell>
            </TableRow>
          ))}

          
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
}

export default Supplier