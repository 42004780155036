import React, { useState, useEffect } from "react";
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../initialState";
import { getInvoice } from "../../actions/invoiceActions";
import { getAllItems } from "../../actions/itemActions";
import { toCommas } from "../../utils/utils";
import styles from "./InvoiceDetails.module.css";
import moment from "moment";
import {
  getProfilesByUser,
  updateProfile,
  getProfile,
} from "../../actions/profile";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid, Avatar } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Spinner from "../Spinner/Spinner";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ProgressButton from "react-progress-button";
import axios from "axios";
import { saveAs } from "file-saver";
import Modal from "../Payments/Modal";
import PaymentHistory from "./PaymentHistory";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  useMediaQuery,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const InvoiceDetails = () => {
  const location = useLocation();
  const [state, setState] = useState(0);
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [commission, setCommission] = useState(0);
  const [Expense, setExpense] = useState(0);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [signatureDataUrl, setSignatureDataUrl] = useState(null);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  //console.log("id", id);
  const { invoice } = useSelector((state) => state.invoices);
  //console.log(invoice);
  const items = useSelector((state) => state.items.items);
  const { profiles } = useSelector((state) => state.profiles);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [printStatus, setPrintStatus] = useState(null);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'

      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
      backgroundColor: "red",
      borderRadius: "10px 10px 0px 0px",
    },
    wpContainer: {
      display: "flex",
      alignItems: "center",
    },
    whatsappIcon: {
      height: "3rem",
      width: "3rem",
      cursor: "pointer",
    },
    circularProgress: {
      marginLeft: "8px", // Add margin for mobile view
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated

      setExpense(invoice.expense);
      setCommission(invoice.commission);
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      setSupplier(invoice.supplier);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      setVat(invoice.vat);
      setCurrency(invoice.currency);
      setSubTotal(invoice.subTotal);
      setTotal(invoice.total);
      setCompany(invoice?.businessDetails?.data);
      setSignatureDataUrl(invoice.signatureDataUrl);
    }
  }, [invoice]);
  useEffect(() => {
    dispatch(
      // getItemsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllItems()
    );
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    // dispatch(getProfilesByUser({ search: user?.result?._id }))
    dispatch(getProfile(user?.userProfile?._id));
  }, [location, dispatch]);
  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid);
  }

  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };

  const createAndPrintPdf = () => {
    setPrintStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-pdf/${invoice.invoiceNumber}`,
        {
          name: invoice.client.name,
          agentCode: invoice.client.agentCode,
          date: invoice.date,
          id: invoice.invoiceNumber,
          notes: invoice.notes,
          subTotal: toCommas(invoice.subTotal),
          total: invoice.total,
          type: invoice.type,
          vat: invoice.vat,
          selling: invoice.selling,
          payable: invoice.payable,
          pax: invoice.pax,
          commission: invoice.commission,
          expense: invoice.expense,
          status: invoice.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-pdf/${invoice.invoiceNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setPrintStatus("success");

        // Open a new browser window for printing the PDF
        const printWindow = window.open(downloadUrl, "_blank");

        // Wait for the PDF to load, then trigger printing
        printWindow.onload = () => {
          printWindow.print();
          // You can close the print window automatically if needed
          // printWindow.close();
        };
      });
    // .then((res) => {
    //   const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    //   // const downloadUrl = window.URL.createObjectURL(pdfBlob);
    //   // setDownloadStatus("success");
    //   // window.open(downloadUrl, "_blank");
    //   saveAs(pdfBlob, `order${invoice.invoiceNumber}.pdf`)
    //   const downloadUrl= `http://orders.sadhanaayurvedics.com/files/Exported-order/order${invoice.invoiceNumber}.pdf`
    //   window.open(downloadUrl,'_blank')
    //   setDownloadStatus("success");
    // });
  };
  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-pdf/${invoice.invoiceNumber}`,
        {
          name: invoice.client.name,
          agentCode: invoice.client.agentCode,
          date: invoice.date,
          id: invoice.invoiceNumber,
          notes: invoice.notes,
          subTotal: toCommas(invoice.subTotal),
          total: invoice.total,
          type: invoice.type,
          vat: invoice.vat,
          selling: invoice.selling,
          payable: invoice.payable,
          pax: invoice.pax,
          commission: invoice.commission,
          expense: invoice.expense,
          status: invoice.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-pdf/${invoice.invoiceNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // const downloadUrl = window.URL.createObjectURL(pdfBlob);
        // setDownloadStatus("success");
        // window.open(downloadUrl, "_blank");
        saveAs(pdfBlob, `order${invoice.invoiceNumber}.pdf`);
        const downloadUrl = `https://qit.mindtree.co.th/files/Exported-order/order${invoice.invoiceNumber}.pdf`;
        window.open(downloadUrl, "_blank");
        setDownloadStatus("success");
      });
  };
  // const downloadPdf = (fileName) => {
  //   const apiUrl = `${process.env.REACT_APP_API}/fetch-pdf/${fileName}`;
  //   axios
  //     .get(apiUrl, { responseType: "blob" })
  //     .then((response) => {
  //       const file = new Blob([response.data], { type: "application/pdf" });
  //       saveAs(file, fileName);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading PDF:", error);
  //     });
  // };

  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/send-pdf-whatsapp`, {
        name: invoice.client.name,
        agentCode: invoice.client.agentCode,
        date: invoice.date,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: invoice.total,
        type: invoice.type,
        vat: invoice.vat,
        selling: invoice.selling,
        payable: invoice.payable,
        pax: invoice.pax,
        commission: invoice.commission,
        expense: invoice.expense,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // //console.log(resres.data)
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setSendStatus("success");
        const encodedFilePath = encodeURIComponent(downloadUrl);
        //console.log("Client Phone Number", invoice.client.phone);
        const whatsappURL = `https://wa.me/${invoice.client.phone}?text=https://qit.mindtree.co.th/files/Exported-order/order${invoice.invoiceNumber}.pdf`;

        window.open(whatsappURL, "_blank");

        // window.open(res.data.whatsappURL, "_blank");
      })
      // .then(() => //console.log("invoice sent successfully"))
      .then(() => {
        setSendStatus("success");
        setLoading(false);
        history.push("/dashboard");
      })
      .catch((error) => {
        //console.log(error);
        setSendStatus("error");
      });
  };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  const [open, setOpen] = useState(false);

  function checkStatus() {
    return totalAmountReceived >= total
      ? "green"
      : status === "Partial"
      ? "#1976d2"
      : status === "Paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  }

  if (!invoice) {
    return <Spinner />;
  }
  // const findItemNameById = (itemId) => {
  //   //console.log("parameter"+itemId);
  //   const item = items.find((item) => item._id ==itemId);
  //   //console.log(user)
  //   return item ? item.name : "";
  // };
  const colorTheme = createTheme({
    palette: {
      primary: {
        main: "#ef7f1a",
      },
    },
  });
  return (
    <div className={styles.PageLayout}>
      <div
        className={styles.buttons}
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "10px",
        }}
      >
        <div style={{ width: isSmallScreen ? "90%" : "auto" }}>
          {user?.result?.userType === "admin" && (
            <button
              className={styles.btn}
              onClick={() => editInvoice(invoiceData._id)}
            >
              <BorderColorIcon style={iconSize} />
              Edit Booking
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginRight: "10px",
          }}
        >
          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
            Download
          </ProgressButton>
          <ProgressButton
            onClick={createAndPrintPdf}
            state={printStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
            Print
          </ProgressButton>
          {/* <Button onClick={()=>IminPrint()}>
              IMIN Print
            </Button> */}
          {/* // if (clientData.phone.trim() === '') {
    //   newErrors.phone = 'Phone is required';
    //   isValid = false;
    // }else if(clientData.phone.length<10||clientData.phone.length>12){
    //   newErrors.phone = 'Please enter a valid phone number.';
    //   isValid = false;} */}

          <ThemeProvider theme={colorTheme}>
            <div className={classes.wpContainer}>
              {loading ? (
                <CircularProgress
                  className={isSmallScreen ? classes.circularProgress : ""}
                />
              ) : (
                <img
                  className={classes.whatsappIcon}
                  src="https://www.sharethis.com/wp-content/uploads/2017/05/WhatsApp.png"
                  alt=""
                  onClick={sendPdf}
                />
              )}
            </div>
          </ThemeProvider>
        </div>
      </div>

      {invoice?.paymentRecords.length !== 0 && (
        <PaymentHistory paymentRecords={invoiceData?.paymentRecords} />
      )}

      <Modal open={open} setOpen={setOpen} invoice={invoice} />
      <div className={styles.invoiceLayout}>
        <Container className={classes.headerContainer}>
          <Grid
            container
            justifyContent="flexStart"
            // style={{ padding: "30px 0px" }}
          >
            {!invoice?.creator?.includes(
              user?.result._id || user?.result?.googleId
            ) ? (
              <Grid item></Grid>
            ) : (
              <Grid
                item
                onClick={() => history.push("/settings")}
                style={{ cursor: "pointer" }}
              >
                <Avatar
                  style={{
                    width: "150px",
                    height: "150px",
                    marginBottom: "5px",
                  }}
                  src={profiles?.logo}
                  alt="Image"
                  className={classes.avatar}
                />
              </Grid>
            )}
            <Grid
              item
              style={{
                marginRight: 20,
                textAlign: "left",
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              <Typography
                variant="h6"
              
                style={{ fontWeight: "bolder" }}
              >
                Date:{" "}
                {invoice
                  ? moment(invoice.date).format("Do MMM YYYY")
                  : moment().format("Do MMM YYYY")}
              </Typography>

              <Typography
                variant="h6"
           
                style={{ fontWeight: "bolder" }}
              >
                Agent Code:{client?.agentCode}
              </Typography>
              <Typography
                variant="h6"
            
                style={{ fontWeight: "bolder" }}
              >
                Agent Name:{client.name}
              </Typography>
              <Typography
                variant="h6"
           
                style={{ fontWeight: "bolder" }}
              >
                Number Of Pax: {invoiceData.pax}
              </Typography>

              {/* <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "bolder" }}
              >
               
              </Typography> */}
              {/* <Typography variant="body2">{client?.email}</Typography> */}
              {/* <Typography variant="body1">{client?.phone}</Typography> */}
              {/* <Typography variant="body2">{client?.address}</Typography>
                <Typography variant="body2">{client?.dueDate}</Typography> */}

              {/* <Typography
                style={{
                  lineSpacing: 1,
                  fontSize: 45,
                  fontWeight: 700,
                  color: "gray",
                }}
              >
                {Number(total - totalAmountReceived) <= 0 ? "Receipt" : "Booking"}
              </Typography>
              <Typography variant="overline" style={{ color: "gray" }}>
                No:{" "}
              </Typography>
              <Typography variant="body2">
                {invoiceData?.invoiceNumber}
              </Typography> */}
            </Grid>
            <Typography variant="body1" style={{marginLeft:"auto",fontWeight:"bold"}}
            >

            Booking ID: {invoiceData?.invoiceNumber}
            </Typography>
               
           
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px", marginBottom: "10px" }}
          >
            <Grid item>
              {/* {invoice?.creator?.includes(user?.result._id) && (
                      <Container style={{marginBottom: '20px'}}>
                        <Typography variant="overline" style={{color: 'gray'}} gutterBottom>From</Typography>
                        <Typography variant="subtitle2">{invoice?.businessDetails?.data[0]?.businessName}</Typography>
                        <Typography variant="body2">{invoice?.businessDetails?.data?.data?.email}</Typography>
                        <Typography variant="body2">{invoice?.businessDetails?.data?.data?.phoneNumber}</Typography>
                        <Typography variant="body2" gutterBottom>{invoice?.businessDetails?.data?.data?.address}</Typography>
                      </Container>
                    )} */}
            </Grid>

            {/* <Container>
              <Typography
                variant="overline"
                style={{ color: "gray", paddingRight: "3px" }}
                gutterBottom
              >
                Supply from
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {supplier.name}
              </Typography>
              <Typography variant="body1">{supplier?.phone}</Typography>
            </Container> */}
          </Grid>
        </Container>

        <form>
          <div>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.heading}>
                      Extra Selling
                    </TableCell>

                    <TableCell className={styles.heading}>
                      Number of Pax
                    </TableCell>
                    <TableCell className={styles.heading}>
                      Commision
                    </TableCell>
                    {/* <TableCell>Disc(%)</TableCell> */}
                    <TableCell className={styles.heading}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.selling?.map((itemField, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row" style={{width:"40%"}}>
                        {" "}
                        <InputBase
                          
                          outline="none"
                          sx={{ ml: 1, flex: 1 }}
                          type="text"
                          name="itemName"
                          value={itemField?.itemName}
                          placeholder="Item name or description"
                          readOnly
                        />{" "}
                      </TableCell>

                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="quantity"
                          value={itemField?.quantity}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="unitPrice"
                          value={itemField?.commission?.commissionValue}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>

                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1, textAlign: "right" }}
                          type="number"
                          name="amount"
                          value={
                            itemField?.quantity *
                            itemField?.commission?.commissionValue
                          }
                          readOnly
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addButton}></div>
          </div>
          <div
            className={styles.invoiceSummary}
            style={{ backgroundColor: "gray" }}
          >
            <div className={styles.summaryItem}>
              <h2>Total Commission</h2>
              <h4
                style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}
              >
                {currency} {toCommas(commission)}
              </h4>
            </div>
          </div>
          <div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.heading}>
                    Less Payable
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData?.payable?.map((itemField, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row" style={{ width: "40%" }}>
                      {" "}
                      <InputBase
                        style={{ width: "100%" }}
                        outline="none"
                        sx={{ ml: 1, flex: 1 }}
                        type="text"
                        name="itemName"
                        value={itemField?.itemName}
                        placeholder="Item name or description"
                        readOnly
                      />{" "}
                    </TableCell>

                    <TableCell align="center">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="quantity"
                        value={itemField?.quantity}
                        placeholder="0"
                        readOnly
                      />{" "}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="unitPrice"
                        value={
                          itemField?.commission?.commissionType === "1"
                            ? (itemField?.commission?.commissionValue / 100) *
                              itemField.unitPrice
                            : itemField?.commission?.commissionValue
                        }
                        placeholder="0"
                        readOnly
                      />{" "}
                    </TableCell>

                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1, textAlign: "right" }}
                        type="number"
                        name="amount"
                        value={
                          itemField?.quantity *
                          (itemField?.commission?.commissionType === "1"
                            ? (itemField?.commission?.commissionValue / 100) *
                              itemField.unitPrice
                            : itemField?.commission?.commissionValue)
                        }
                        readOnly
                      />{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div
            className={styles.invoiceSummary}
            style={{ backgroundColor: "gray" }}
          >
            <div className={styles.summaryItem}>
              <h2>Total Expense</h2>
              <h2
                style={{ color: "black", lineHeight: "8px" }}
              >
                {currency} {toCommas(Expense)}
              </h2>
            </div>
          </div>

          <div
            className={styles.invoiceSummary}
            style={{ marginTop: "20px", backgroundColor: "yellow" }}
          >
            {/* <div className={styles.summary}>Booking Summary</div>
            <div className={styles.summaryItem}>
              <p>Total Items:</p>
              <h4>{invoiceData.items.length}</h4>
            </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>{`Discount(${rates}%):`}</p>
                        <h4>{vat}</h4>
                    </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>Total</p>
                        <h4>{currency} {toCommas(total)}</h4>
                    </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>Paid</p>
                        <h4>{currency} {toCommas(totalAmountReceived)}</h4>
                    </div> */}

            <div className={styles.summaryItem}>
              <h1 style={{ color: "Black" }}>
                Total {commission > Expense ? "Receivable" : "Payable"}
              </h1>
              <h1
                style={{ color: "black", lineHeight: "8px" }}
              >
                {currency} {toCommas(total - totalAmountReceived)}
              </h1>
            </div>
          </div>

          <div className={styles.note}>
            <h4 style={{ marginLeft: "-10px" }}>Notes/Payment Info</h4>
            <p style={{ fontSize: "14px" }}>{invoiceData.notes}</p>
          </div>
          <div className={styles.signImg}>
            {signatureDataUrl && (
              <>
                <h4 style={{ marginLeft: "-10px" }}>Signature</h4>
                <img
                  src={signatureDataUrl}
                  alt="signature"
                  className="signature"
                  width={100}
                  height={50}
                />
              </>
            )}
          </div>
          {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        </form>
      </div>
    </div>
  );
};

export default InvoiceDetails;
