import React, { useRef } from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "./SignaturePad.css"; // Your custom styles
import SignatureCanvas from "react-signature-canvas";
import { useSnackbar } from "react-simple-snackbar";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 300,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function SignaturePad({ signatureDataUrl, setSignatureDataUrl }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const sigCanvas = useRef();

  const create = () => {
    if (!sigCanvas.current.isEmpty()) {
      const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      console.log("Signature URL: " + URL);
      setSignatureDataUrl(URL);
      setOpenModal(false);
    } else {
      // Display the snackbar message when the canvas is empty
      openSnackbar("Please Sign");
    }
   
  };


  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => setOpenModal(true)}
      >
        Create Signature
      </Button>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Paper className={classes.paper}>
          <div className="sigPadContainer">
            <SignatureCanvas
              penColor="black"
              canvasProps={{ className: "sigCanvas" }}
              ref={sigCanvas}
            />
            <hr />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => sigCanvas.current.clear()}
            >
              Clear
            </Button>
          </div>
          <div className="modal__bottom">
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={create}
          >
            Create
          </Button>
        </Paper>
      </Modal>

      {/* Display the saved signature */}
      {/* <img src={signatureDataUrl} alt="Signature" /> */}
    </div>
  );
}

export default SignaturePad;
