/* eslint-disable */
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import { useDispatch, useSelector } from "react-redux";
import { createClient, getAllClients } from "../../actions/clientActions";
import { useLocation } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddClient = ({ setOpen, open }) => {
  const location = useLocation();
  const [clientData, setClientData] = useState({
    name: "",
    phone: "",
    agentCode: "",
    userId: [],
  });
  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state.clients);
  const user = JSON.parse(localStorage.getItem("profile"));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setClientData({ ...clientData, userId: [checkId] });
    } else {
      setClientData({ ...clientData, userId: [user?.result?.googleId] });
    }
  }, [location]);
  useEffect(() => {
    dispatch(getAllClients());
    // dispatch(getClientsByUser({ search: user?.result?._id || user.result.googleId }));
  }, [location]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the specific field
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (clientData.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    }
    // if (clientData.phone.trim() === "") {
    //   newErrors.phone = "Phone is required";
    //   isValid = false;
    // } else if (clientData.phone.length < 10 || clientData.phone.length > 12) {
    //   newErrors.phone = "Please enter a valid phone number.";
    //   isValid = false;
    // }
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (clientData.email.trim() === ''||!emailRegex.test(clientData.email.trim())) {
    //   newErrors.email = 'Please enter a valid email address.';
    //   isValid = false;
    // }

    // if (clientData.address.trim() === '') {
    //   newErrors.address = 'Address is required';
    //   isValid = false;
    // }
    if (clientData.agentCode.trim() === "") {
      newErrors.agentCode = "Agent Code is required";
      isValid = false;
    } else if (
      clients.some(
        (client) =>
          client.agentCode.toLowerCase() === clientData.agentCode.toLowerCase()
      )
    ) {
      newErrors.agentCode = "Agent Code is already in use";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmitClient = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Check if the form is valid before submitting

      dispatch(createClient(clientData, openSnackbar));

      clear();
      handleClose();
    }
  };

  const clear = () => {
    setClientData({ name: "", phone: "", agentCode: "", userId: [] });
    setErrors({}); // Clear the error messages
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            paddingLeft: "20px",
            backgroundColor: "#ef7f1a",
            color: "white",
          }}
        >
          New Agent/Hotel
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label=" Name"
                fullWidth
                name="name"
                value={clientData.name}
                onChange={handleChange}
                error={!!errors.name} // Check if error exists
                helperText={errors.name} // Display error message
              />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Contact Person"
                  fullWidth
                  name="contactPerson"
                  value={clientData.contactPerson}
                  onChange={handleChange}
                  error={!!errors.contactPerson} // Check if error exists
                  helperText={errors.contactPerson} // Display error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  name="email"
                  value={clientData.email}
                  onChange={handleChange}
                  error={!!errors.email} // Check if error exists
                  helperText={errors.email} // Display error message
                  inputProps={{
                    pattern: "^[^\s@]+@[^\s@]+\.[^\s@]+$", // Email format regex pattern
                  }}
                />
              </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Phone"
                fullWidth
                name="phone"
                value={clientData.phone}
                onChange={handleChange}
                error={!!errors.phone} // Check if error exists
                helperText={errors.phone} // Display error message
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Agent Code"
                fullWidth
                name="agentCode"
                value={clientData.agentCode}
                onChange={handleChange}
                error={!!errors.agentCode} // Check if error exists
                helperText={errors.agentCode} // Display error message
              />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Address"
                  fullWidth
                  name="address"
                  value={clientData.address}
                  onChange={handleChange}
                  error={!!errors.address} // Check if error exists
                  helperText={errors.address} // Display error message
                />
              </Grid>
             
             
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Payment Term Days"
                  fullWidth
                  type="number"
                  name="dueDate"
                  value={clientData.dueDate} //
                  onChange={handleChange}
                  error={!!errors.dueDate} // Check if error exists
                  helperText={errors.dueDate} // Display error message
                 
                />
              </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitClient}
            variant="contained"
            style={{
              marginRight: "25px",
              backgroundColor: "#ef7f1a",
              color: "white",
            }}
          >
            Save Customer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddClient;
