import React, { createContext, useState } from 'react';

export const StatsContext = createContext();

export const StatsProvider = ({ children }) => {
    const [currStat,setCurrStat]=useState(null);
  
  return (
    <StatsContext.Provider value={{currStat,setCurrStat }}>
      {children}
    </StatsContext.Provider>
  );
};
