import React, { useEffect, useState, useMemo } from "react";
import { toCommas } from "../../utils/utils";
import styles from "./Dashboard.module.css";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesByUser, getInvoices,getInvoicesByPage } from "../../actions/invoiceActions";
import { getUsers } from "../../actions/auth";
import { getMeetings } from "../../actions/meetingActions";
import { getAllClients } from "../../actions/clientActions";
import Empty from "../svgIcons/Empty";
import Chart from "./Chart";
// import Donut from './Donut'
import moment from "moment";
import {
  Check,
  Pie,
  Bag,
  Card,
  Clock,
  Frown,
  Order,
  Rupee,
  Report,
  SalesPerson,
  Item,
} from "./Icons";
import Spinner from "../Spinner/Spinner";

const Dashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { users } = useSelector((state) => state.auth);
  const [dueDays, setDueDays] = useState(5);
  const [overdueClientsResult, setOverdueClientsResult] = useState([]);
  //(users);
  const clients = useSelector((state) => state.clients.clients);
  const [rows, setRows] = useState([]);
  const [reports, setReports] = useState([]);
  const  {invoices}  = useSelector((state) => state?.invoices.invoices);
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const { meetings } = useSelector((state) => state?.meetings);
  //(invoices);
  useEffect(() => {
    dispatch(getInvoicesByPage(1,200));
  }, [location, dispatch]);
  useEffect(() => {
    
    const today = moment().format("YYYY-MM-DD");
      const rows = invoices.filter((inv) =>
        moment(inv.date).isSame(today, "day")
      );
      setRows(rows);
    
    // eslint-disable-next-line
  }, [invoices]);

  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);
  // const unpaid = invoices?.filter((invoice) => (invoice.status === 'Unpaid') || (invoice.status === 'Partial'))
  const overDue = rows?.filter(
    (invoice) => invoice.dueDate <= new Date().toISOString()
  );
  const admins = users.filter((user) => user.userType === "admin");
  const regularUsers = users.filter((user) => user.userType === "user");
  const activeUsers = regularUsers.filter((user) => user.isActive === true);
  let paymentHistory = [];
  for (let i = 0; i < rows.length; i++) {
    let history = [];
    if (rows[i].paymentRecords !== undefined) {
      history = [...paymentHistory, rows[i].paymentRecords];
      paymentHistory = [].concat.apply([], history);
    }
  }

  //sort payment history by date
  const sortHistoryByDate = paymentHistory.sort(function (a, b) {
    var c = new Date(a.datePaid);
    var d = new Date(b.datePaid);
    return d - c;
  });

  let totalPaid = 0;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].totalAmountReceived !== undefined) {
      totalPaid += rows[i].totalAmountReceived;
    }
  }

  let totalAmount = 0;
  //const currentDate = new Date(); // Get the current date

  for (let i = 0; i < rows.length; i++) {
    // const rowDate = new Date(rows[i].createdAt); // Convert row date to a Date object

    // Compare year, month, and day of current date and row date
    // if (
    //   rowDate.getFullYear() === currentDate.getFullYear() &&
    //   rowDate.getMonth() === currentDate.getMonth() &&
    //   rowDate.getDate() === currentDate.getDate()
    // )
    {
      totalAmount += rows[i].total;
    }
  }
  const handleDueDaysChange = (event) => {
    setOverdueClientsResult([]);
    setDueDays(event.target.value);
    // Update the state with the input value
  };
 

  // const unpaidInvoice = invoices?.filter(
  //   (invoice) => invoice.status === "Unpaid"
  // );
  const paid = rows?.filter((invoice) => invoice.status === "Paid");
  const partial = rows?.filter((invoice) => invoice.status === "Partial");

  if (!user) {
    history.push("/login");
  }
  useEffect(() => {
    const today = moment();
    const overdueClients = clients.filter((client) => {
      const clientInvoices = rows.filter(
        (invoice) => invoice.client._id === client._id
      );

      if (clientInvoices.length === 0) {
        return true;
      }

      const lastInvoiceDate = moment.max(
        clientInvoices.map((invoice) => moment(invoice.date))
      );

      const dueDate = moment(today).subtract(dueDays, "days");

      return lastInvoiceDate.isBefore(dueDate, "day");
    });

    setOverdueClientsResult(overdueClients);
  }, []);

  const today = moment();
  // Filter clients for which invoices are not created in the last due days
  const handleSubmit = useMemo(
    () => () => {
      const overdueClients = clients.filter((client) => {
        const clientInvoices = rows.filter(
          (invoice) => invoice.client._id === client._id
        );

        if (clientInvoices.length === 0) {
          return true;
        }

        const lastInvoiceDate = moment.max(
          clientInvoices.map((invoice) => moment(invoice.date))
        );

        const dueDate = moment(today).subtract(dueDays, "days");

        return lastInvoiceDate.isBefore(dueDate, "day");
      });

      setOverdueClientsResult(overdueClients);
    },
    [clients, invoices, dueDays, today]
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        {/* <Spinner /> */}
        <Empty />
        <p style={{ padding: "40px", color: "gray" }}>
          Nothing to display. Click the plus icon to start creating
        </p>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.dateContainer}>
        <h2 className={styles.date}>{moment().format("Do MMM YYYY")}</h2>
      </div>
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          <Link to="/todayBookings" className={styles.link}>
            <li className={styles.listItem}>
              <div>
                <p>{rows.length}</p>
                <h2>Todays Bookings</h2>
              </div>
              <div>
                <Order />
              </div>
            </li>
          </Link>
          
         
          <Paper elevation={2} style={{ padding: "20px", width: "350px" }}>
            <div>
              <div style={{ display: "flex", gap: "5px" }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Zero Booking in past{" "}
                  <input
                    id="dueDays"
                    type="number"
                    value={dueDays}
                    onChange={handleDueDaysChange}
                    style={{
                      width: "30px",
                      fontSize: "inherit",
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      backgroundColor: "#f9f9f9",
                    }}
                  />{" "}
                  Days
                </Typography>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Go!
                </Button>
              </div>
              {overdueClientsResult && (
                <div className={styles.tableContainer}>
                  <TableContainer>
                    <Table style={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Code</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {overdueClientsResult.map((client) => (
                          <TableRow key={client.id} style={{ border: "none" }}>
                            <TableCell>
                              <Typography variant="body1">
                                {client.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {client.agentCode}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </Paper>
          </ul>
      </section>
    </div>
  );
};

export default Dashboard;
